import './css/normalize.css';
import './css/skeleton-auto.css';
import './css/brands.css';
import './css/brands-extended.css';
import './css/brands-custom.css';
import './css/overrides.css';

const icon = "https://cdn.mestro.cc/static/nanolight-icon.svg"

function LittleLink() {
  return (
    /* Primary Page Layout
    –––––––––––––––––––––––––––––––––––––––––––––––––– */
    <div className="container">
      <div className="row">
        <div className="column" style={{ marginTop: "10%" }}>
          {/*

          ## Getting Started with LittleLink

          This page has been built with every pre-designed button available in LittleLink by default. You can rearrange and delete as needed.

          You can add your own brand or others brands you may need in the `css/brands.css` file. 

          You can add custom icons to `images/icons/...`. It is recommended to use a 24x24 .SVG.

          Edit the "Your Image Here" section to add your own personal branding, like a picture of yourself or your brand logo!

          Edit the "Title" section to change the page heading. You can use something like your name, your social handle, or your brand name.

          Edit the "Short Bio" section tell users about yourself or your brand.

        */}

          {/* Your Image Here */}
          <img src={icon} className='avatar' srcSet={`${icon} 2x`} alt='' fetchPriority='high'/>

          {/* Title */}
          <h1>Mestro / Nanolight</h1>

          {/* Short Bio */}
          <p>Software Enthusiast, 3D Decimator, Audio Cruncher and overall nerd.</p>

          <div className="button button-default line" ></div>

          <p>Find me on one of the places below.</p>

          {/*

          ## Breaking down <a> attributes:
          
          1.) className="button button-default" | The first "button" here is telling this <a> tag that it should make this element a button and applies the default styling in `css/brands.css`.
          The second portion, button-default, is declaring the specific brand style you would like to apply. Here we're applying the "default" style and color.
          If you want to make this button to use the brand colors for Discord, just change "button-default" to "button-discord". Brands are found in `css/brands.css`. You can always edit & add your own there.

          2.) Replace the # in href="#" with the desired target URL for the button.

          3.) target="_blank" | This attribute opens links in a new tab. Remove this attribute to prevent links from opening in a new tab.

          4.) rel="noreferrer" | This attribute instructs the browser to navigate to the target resource without granting the new browsing context access to the document that opened it.
          This is especially useful when opening untrusted links. https://developer.mozilla.org/en-US/docs/Web/HTML/Link_types/noopener

          5.) role="button" | The button role identifies an element as a button to assistive technology such as screen readers.

          ## Breaking down the <img> attributes:
          
          1.) className="icon" | This className is telling the <img> tag that it should use the styling for icons found in `css/brands.css`.

          2.) src="images/icons/[icon-name].svg" | This defines the icon you would like to display from the 'images/icons/' folder. For example, you can change this to src="images/icons/discord.svg" to use the Discord icon.
          Add your own 24x24 icons to the "icons" folder to reference them. We recommend providing a SVG.

          3.) alt="" | Since the text at the end of the snippet, "..>[Button Text]</a><br />", explains what the button is, we use "alt=""" to nullify the icon annoucement from the accessibility tree. 
          This can improve the experience for assistive technology users by hiding what is essentially duplicated

        */}

          <a className="button button-discord" href="https://discord.mestro.cc" target="_blank" rel="noreferrer" role="button"><img className="icon" aria-hidden="true" src="./static-content/app-icons/discord.svg" alt="Discord Logo" />Discord Server</a><br />
          <a className="button button-x" href="https://twitter.mestro.cc" target="_blank" rel="noreferrer" role="button"><img className="icon" aria-hidden="true" src="./static-content/app-icons/x.svg" alt="X Logo" />Twitter / X</a><br />
          <a className="button button-twitch" href="https://twitch.mestro.cc" target="_blank" rel="noreferrer" role="button"><img className="icon" aria-hidden="true" src="./static-content/app-icons/twitch.svg" alt="Twitch Logo" />Twitch</a><br />
          <a className="button button-yt" href="https://yt.mestro.cc" target="_blank" rel="noreferrer" role="button"><img className="icon" aria-hidden="true" src="./static-content/app-icons/youtube.svg" alt="Youtube Logo" />Youtube</a><br />
          <a className="button button-yt" href="https://vods.mestro.cc" target="_blank" rel="noreferrer" role="button"><img className="icon" aria-hidden="true" src="./static-content/app-icons/youtube.svg" alt="Youtube Logo" />Stream VODs</a><br />

          <div className="button button-default line" ></div>

          <a className="button button-patreon" href="https://patreon.mestro.cc" target="_blank" rel="noreferrer" role="button"><img className="icon" aria-hidden="true" src="./static-content/app-icons/patreon.svg" alt="Patreon Logo" />Patreon</a><br />
          <a className="button button-ko-fi" href="https://kofi.mestro.cc" target="_blank" rel="noreferrer" role="button"><img className="icon" aria-hidden="true" src="./static-content/app-icons/ko-fi.svg" alt="Ko-fi Logo" />Ko-fi</a><br />

          <div className="button button-default line" ></div>

          <a className="button button-github" href="https://github.mestro.cc" target="_blank" rel="noreferrer" role="button"><img className="icon" aria-hidden="true" src="./static-content/app-icons/github.svg" alt="GitHub Logo" />GitHub</a><br />
          <a className="button button-uptime-kuma" href="https://uptime.mestro.cc" target="_blank" rel="noreferrer" role="button"><img className="icon" aria-hidden="true" src="./static-content/app-icons-custom/uptime-kuma.svg" alt="Uptime Kuma Logo" />Service Uptimes</a><br />

          <div className="button button-default line" ></div>

          <a className="button button-gumroad" href="https://gumroad.mestro.cc" target="_blank" rel="noreferrer" role="button"><img className="icon" aria-hidden="true" src="./static-content/app-icons-extended/gumroad.svg" alt="Gumroad Logo" />Gumroad</a><br />
          <a className="button button-booth" href="https://booth.mestro.cc" target="_blank" rel="noreferrer" role="button"><img className="icon" aria-hidden="true" src="./static-content/app-icons-custom/booth.png" alt="Booth Logo" />Booth</a><br />

          <div className="button button-default line" ></div>

          <a className="button button-steam-alt" href="https://steam.mestro.cc" target="_blank" rel="noreferrer" role="button"><img className="icon" aria-hidden="true" src="./static-content/app-icons/steam.svg" alt="Steam Logo" />Steam</a><br />
          <a className="button button-vrchat" href="https://vrchat.mestro.cc" target="_blank" rel="noreferrer" role="button"><img className="icon" aria-hidden="true" src="./static-content/app-icons/vrchat.svg" alt="VRChat Logo" />VRChat</a><br />
          <a className="button button-osu" href="https://osu.mestro.cc" target="_blank" rel="noreferrer" role="button"><img className="icon" aria-hidden="true" src="./static-content/app-icons-extended/osu.svg" alt="osu! Logo" />osu!</a><br />

          <div className="button button-default line" ></div>

          <a className="button button-furaffinity" href="https://furaffinity.net/user/nanolight/" target="_blank" rel="noreferrer" role="button"><img className="icon" aria-hidden="true" src="./static-content/app-icons-custom/furaffinity.png" alt="FurAffinity Logo" />FurAffinity</a><br />
          <a className="button button-inkbunny" href="https://inkbunny.net/Nanolight" target="_blank" rel="noreferrer" role="button"><img className="icon" aria-hidden="true" src="./static-content/app-icons-custom/inkbunny.png" alt="InkBunny Logo" />InkBunny</a><br />

          <br />
          {/* 
            Footer:
            This includes a link to privacy.html page which can be setup for your Privacy Policy.
            This also includes a link to the LittleLink repository to make forking LittleLink easier.
            You can edit or remove anything here to make your own footer.
        */}
          {/*<p><a href="privacy.html">Privacy Policy</a> | Build your own by forking <a href="https://littlelink.io" target="_blank" rel="noreferrer" role="button">LittleLink</a>.</p>*/}

        </div>
      </div>
    </div>
    /* End Document
    –––––––––––––––––––––––––––––––––––––––––––––––––– */
  );
}

export default LittleLink;
